<template lang="pug">
include ../../../configs/template
v-card
  v-card-title
    div.flex-row-sb
      div.text-uppercase.text-left {{ $t('signingDistribution') }}
      div.documentIconControl
        v-icon(@click="$router.push('/back-office/auto-signing-distribution')").close mdi-close
  v-card-text
    div(v-for="distribution in items" :key="distribution.id").w-100.d-flex.align-center
      div.w-100.max-width-350.text-left {{ distribution.signer_full_name }} (ID: {{ distribution.signer }})
      div.ml-3.w-10
        +field('distribution.distribution')(
          v-model.number="distribution.distribution"
          type="number"
          suffix="%")
  v-card-actions
    div.w-100.mt-3
      v-btn(
        :loading="isLoading"
        color="success"
        @click="saveDistribution") {{ $t('save') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  mounted () {
    if (!this.items.length) this.getAutoSigningDistribution()
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.autoSigningDistribution.filter(item => item.can_auto_sign),
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getAutoSigningDistribution', 'updateAutoSigningDistribution']),

    saveDistribution () {
      const distributionSum = this.items.reduce((a, b) => a + (b.distribution || 0), 0)
      if (![0, 100].includes(distributionSum)) return this.$notification.warning('signingDistributionSum')

      this.updateAutoSigningDistribution(this.items).then(response => {
        if (response?.code === 200) {
          this.$notification.success('successAutoSingedDistributionChange')
          this.$router.push('/back-office/auto-signing-distribution')
        }
      })
    }
  }
}
</script>
